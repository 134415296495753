var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Importeer .xslx bestand")]),_c('b-form-file',{staticClass:"mb-2",attrs:{"accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"input":_vm.handleDrop},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),(_vm.duplicates.length > 0)?_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_vm._v(" Er "+_vm._s(_vm.duplicates.length == 1 ? "is" : "zijn")+" "+_vm._s(_vm.duplicates.length == 0 ? "geen" : _vm.duplicates.length)+" groep"+_vm._s(_vm.duplicates.length == 1 ? "" : "en")+" die reeds in je database "+_vm._s(_vm.duplicates.length == 1 ? "staat" : "staan")+". Selecteer de groep(en) die je wil overschrijven. ")])]):(_vm.importObjects.length > 0)?_c('b-alert',{staticClass:"mb-2",attrs:{"variant":"success","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" Data ziet er goed uit! Klaar om te importeren. ")])]):_vm._e(),(
      _vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length) >
        500 || _vm.data.length > 500
    )?_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body"},[_vm._v(" We ondersteunen max. 500 lijnen per import. ")])]):_vm._e(),(_vm.duplicates.length > 0)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","disabled":_vm.loading ||
      (_vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length) ==
        0 &&
        _vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length) > 0)},on:{"click":_vm.importGroups}},[_vm._v(" "+_vm._s(_vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length))+" groepen importeren ")]):_vm._e(),(_vm.duplicates.length > 0)?_c('b-table',{staticClass:"my-2",attrs:{"striped":"","hover":"","items":_vm.duplicates,"fields":_vm.tableColumns},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{attrs:{"checked":_vm.selectedDups.length === _vm.duplicates.length,"indeterminate":_vm.selectedDups.length < _vm.duplicates.length && _vm.selectedDups.length > 0},on:{"change":_vm.selectAll}})]},proxy:true},{key:"cell(selected)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"name":"selected-items","value":data.item.uoid},model:{value:(_vm.selectedDups),callback:function ($$v) {_vm.selectedDups=$$v},expression:"selectedDups"}})]}}],null,false,270597450)}):_vm._e(),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","disabled":_vm.loading ||
      (_vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length) ==
        0 &&
        _vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length) > 0)},on:{"click":_vm.importGroups}},[_vm._v(" "+_vm._s(_vm.importObjects.length - (_vm.duplicates.length - _vm.selectedDups.length))+" groepen importeren ")]),_c('b-modal',{attrs:{"title":"Velden koppelen","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.matchFieldsVisible),callback:function ($$v) {_vm.matchFieldsVisible=$$v},expression:"matchFieldsVisible"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.setFields($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Naam","label-for":"name"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"disabled":_vm.loading,"closeOnSelect":true,"options":_vm.headers},model:{value:(_vm.matchData.name),callback:function ($$v) {_vm.$set(_vm.matchData, "name", $$v)},expression:"matchData.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Kleur","label-for":"name"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"disabled":_vm.loading,"closeOnSelect":true,"options":_vm.headers},model:{value:(_vm.matchData.color),callback:function ($$v) {_vm.$set(_vm.matchData, "color", $$v)},expression:"matchData.color"}})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[_vm._v(" Opslaan ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }