<template>
  <b-card>
    <label for="wildcard">Importeer .xslx bestand</label>
    <b-form-file
      class="mb-2"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      v-model="file"
      @input="handleDrop"
    />
    <b-alert v-if="duplicates.length > 0" class="mb-2" show variant="primary"
      ><div class="alert-body">
        Er {{ duplicates.length == 1 ? "is" : "zijn" }}
        {{ duplicates.length == 0 ? "geen" : duplicates.length }} groep{{
          duplicates.length == 1 ? "" : "en"
        }}
        die reeds in je database
        {{ duplicates.length == 1 ? "staat" : "staan" }}. Selecteer de
        groep(en) die je wil overschrijven.
      </div></b-alert
    >
    <b-alert
      v-else-if="importObjects.length > 0"
      variant="success"
      class="mb-2"
      show
      ><div class="alert-body">
        Data ziet er goed uit! Klaar om te importeren.
      </div></b-alert
    >
    <b-alert
      v-if="
        importObjects.length - (duplicates.length - selectedDups.length) >
          500 || data.length > 500
      "
      show
      variant="danger"
      class="mb-2"
      ><div class="alert-body">
        We ondersteunen max. 500 lijnen per import.
      </div></b-alert
    >
    <b-button
      v-if="duplicates.length > 0"
      variant="primary"
      @click="importGroups"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="
        loading ||
        (importObjects.length - (duplicates.length - selectedDups.length) ==
          0 &&
          importObjects.length - (duplicates.length - selectedDups.length) > 0)
      "
    >
      {{
        importObjects.length - (duplicates.length - selectedDups.length)
      }}
      groepen importeren
    </b-button>

    <b-table
      class="my-2"
      v-if="duplicates.length > 0"
      striped
      hover
      :items="duplicates"
      :fields="tableColumns"
    >
      <!-- A custom formatted header cell for field 'selected' -->
      <template #head(selected)>
        <b-form-checkbox
          @change="selectAll"
          :checked="selectedDups.length === duplicates.length"
          :indeterminate="
            selectedDups.length < duplicates.length && selectedDups.length > 0
          "
        ></b-form-checkbox>
      </template>
      <template #cell(selected)="data">
        <b-form-checkbox
          name="selected-items"
          v-model="selectedDups"
          :value="data.item.uoid"
        ></b-form-checkbox>
      </template>
    </b-table>

    <b-button
      variant="primary"
      @click="importGroups"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="
        loading ||
        (importObjects.length - (duplicates.length - selectedDups.length) ==
          0 &&
          importObjects.length - (duplicates.length - selectedDups.length) > 0)
      "
    >
      {{
        importObjects.length - (duplicates.length - selectedDups.length)
      }}
      groepen importeren
    </b-button>

    <b-modal
      title="Velden koppelen"
      hide-footer
      v-model="matchFieldsVisible"
      no-close-on-backdrop
    >
      <b-form @submit.prevent="setFields">
        <b-row>
          <!-- Field: Firstname -->
          <b-col cols="12" md="12">
            <b-form-group label="Naam" label-for="name">
              <v-select
                v-model="matchData.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Firstname -->
          <b-col cols="12" md="12">
            <b-form-group label="Kleur" label-for="name">
              <v-select
                v-model="matchData.color"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :disabled="loading"
        >
          Opslaan
        </b-button>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert,
  BFormFile,
  BTable,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";

import vSelect from "vue-select";

import Ripple from "vue-ripple-directive";

import XLSX from "xlsx";

import { db } from "@/firebase/firebaseConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import mainMixin from '@/mixins/mainMixin';

export default {
  mixins: [mainMixin],
  directives: { Ripple },
  components: {
    BFormCheckbox,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert,
    BFormFile,
    BTable,
    BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      file: null,
      headers: [],
      data: [],
      importObjects: [],
      loading: false,
      matchFieldsVisible: false,
      selectedDups: [],
      tableColumns: [
        { key: "name", label: "Name" },
        { key: "color", label: "Kleur"},
      ],
      matchData: {
        name: null,
        color: null
      },
    };
  },
  created() {
    this.loadGroups();
  },
  computed: {
    duplicates() {
      return (
        this.importObjects
          .filter((item) => item.duplicate == true)
          .map((item) => {
            return {
              name: item.name,
        color: item.color
            };
          }) ?? []
      );
    },
  },
  methods: {
    selectAll() {
      if (this.selectedDups.length === this.duplicates.length) {
        this.selectedDups = [];
      } else {
        this.selectedDups = this.duplicates.map((d) => d.uoid);
      }
    },
    importGroups() {
      this.loading = true;
      var batch = db.batch();
      this.importObjects.forEach((obj, i) => {
        console.log(obj, obj.uoid);
        if (!obj.duplicate || this.selectedDups.includes(obj.uoid)) {
          
          if(obj.duplicate){
            delete obj.duplicate;
          }
          batch.set(
            db
              .collection("organisations")
              .doc(this.$store.state.organisation.id)
              .collection("groups")
              .doc(),
            obj,
            { merge: true }
          );
        }
      });
      return batch
        .commit()
        .then(() => {
          return  this.$store
          .dispatch(
            "loadGroups",
            this.$store.state.organisation.id
          )
        })
          .then(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alle rijen geimporteerd!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          return;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Er ging iets fout!",
              text: error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    handleDrop(e) {
      // only use files[0]
      this.readerData(e);
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C = undefined;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}`; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },

    readerData(rawFile) {
      return new Promise((resolve) => {
        try{
          const reader = new FileReader();
        reader.onload = (e) => {
          var res = [];
          var sheetNames = [];
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          workbook.SheetNames.forEach((sheet) => {
            const worksheet = workbook.Sheets[sheet];
            const header = this.getHeaderRow(worksheet);
            const results = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            const meta = { sheetName: sheet };
            res.push({ header, results, meta });
            sheetNames.push(sheet);
          });
          this.headers = res[0].header;
          this.data = res[0].results;
          if(this.data.length <= 500){
              this.matchFieldsVisible = true;
          }
          resolve();
        };
        reader.onerror = function(ex) {
          console.log(ex);
        };

        reader.readAsArrayBuffer(rawFile);
        } catch (error){
          console.log(error);
          reject(error);
        }
        
      });
     
    },
    loadGroups() {
      if (
        !(
          this.$store.state.groups &&
          this.$store.state.groups.length > 1
        )
      ) {
        this.$store.dispatch(
          "loadGroups",
          this.$store.state.organisation.id
        );
      }
    },
    setFields() {
      this.buildObjects();
      this.matchFieldsVisible = false;
    },
    buildObjects() {
      var order = this.$store.state.groups.length > 0 ? this.$store.state.groups[this.$store.state.groups.length-1].o+1 : 0;
      this.data.forEach((d, i) => {
        var obj = {
          
          name:
            d[this.matchData.name] ?? "",
          color: d[this.matchData.color] != null && d[this.matchData.color] != "" ? d[this.matchData.color] : null,
          active: true,
          logo: null,
          o: order,
          organisationId: this.$store.state.organisation.id,
          organisationName: this.$store.state.organisation.shortName,
          type: 'members'
        };
        order++;
        
        if (this.checkForDuplicates(obj) > -1) {
          obj.duplicate = true;
          obj.uoid = this.$store.state.userManagement.users[
            this.checkForDuplicates(obj)
          ].uoid;
        } else {
          obj.duplicate = false;
        }
        if(!obj.duplicate){
          
        }
        this.importObjects.push(obj);
      });
      this.selectAll();
    },
    checkForDuplicates(obj) {
      return this.$store.state.groups.findIndex(
        (v) =>
          (obj.name && v.name == obj.name)
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>